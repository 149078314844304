export function Login() {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontSize: "30px",
            fontWeight: "700",
            textAlign: "center",
            fontFamily: "Poppins",
          }}
        >
          Estamos trabalhando...
        </h1>
        <img src="/assets/building.gif" alt="" style={{ width: "400px" }} />
      </div>
    </div>
  );
}
