import "./index.css";

export function Header() {
  return (
    <header>
      <img src="/assets/logo.png" alt="" />

      <div className="user">
      </div>
    </header>
  );
}
