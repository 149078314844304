import { Header } from "../header";

export function Layout({ children }: any) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Header />
      <div style={{ height: "100%", width: "100%" }}>{children}</div>
    </div>
  );
}
