import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Dashboard } from "../pages/dashboard";
import { Incards } from "../pages/dashboard/incards";

export function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="dashboard">
          <Route path="incards/:id" element={<Incards />} />
          <Route path="" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        <Route path="*" element={<Navigate to="dashboard" />} />
      </Routes>
    </BrowserRouter>
  );
}
