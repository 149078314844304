import { Link } from "react-router-dom";

export function CardList() {
  return (
    <div className="incards">
      <h1>Seus Incards</h1>

      <div className="cards-list">
        <Link to="/dashboard/incards/0" className="card">
          <div className="img">
            <img src="/assets/tabloide-banner.png" alt="" />
          </div>

          <h2>Festival de Orgânicos</h2>
          <h3>22/06/2023</h3>
        </Link>
        <Link to="/dashboard/incards/0" className="card">
          <div className="img">
            <img src="/assets/tabloide-banner.png" alt="" />
          </div>

          <h2>Festival de Orgânicos</h2>
          <h3>22/06/2023</h3>
        </Link>
        <Link to="/dashboard/incards/0" className="card">
          <div className="img">
            <img src="/assets/tabloide-banner.png" alt="" />
          </div>

          <h2>Festival de Orgânicos</h2>
          <h3>22/06/2023</h3>
        </Link>
      </div>
    </div>
  );
}
