import { createContext, useContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Layout } from "../components/layout";
import { Login } from "../pages/login";
import { User } from "../types/user.type";

export const AuthContext = createContext({});

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<User>();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("authorization")) {
      setUser({
        id: "2389283-DK3K09K-KD93KD-394K9D-39KDD",
        name: "Fernando",
        email: "fernandopovinsky@hotmail.com",
      });
    }
    setLoading(false);
  }, []);

  if (isLoading) {
    return <h1>Carregando...</h1>;
  }

  if (!user) {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Routes>
      </BrowserRouter>
    );
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <Layout>{children}</Layout>
    </AuthContext.Provider>
  );
}

export function useAuthentication() {
  const context = useContext(AuthContext);

  if (!context) {
    throw "useAuthentication must be inside AuthProvider";
  }

  return context;
}
